import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`🔭 Explorers`}</h1>
    <p>{`This is a curated collection of data exploration interfaces gathered from 2009 to 2021. Most of this collection is focused on web based interfaces.`}</p>
    <p>{`Entries are divided into those exploration interfaces which involve some kind of statistical model or machine learning algorithm, and those that are more directly mapped 1 to 1 with the underlying data. There is, further, a list of articles and also a list of products & tools. This list will continue to expand.`}</p>
    <h2>{`Machine learning / statistical models / simulations / latent space explorers`}</h2>
    <p>{`In some of these entries, a machine learning model itself is being explored. That is to say, as in the case of the "activation atlas" from OpenAI & Google Brain, that an interface is an aid to one trying to understand a model. In other cases, like "100 years of sci fi", data is being clustered using machine learning models, but the data is what is explored. In some, like NVidia's StyleGAN2, the explorer is a way to scrub through a latent space, and explore possibilities and combinations. In still others, like the epidemic calculator, an interface is provided to the user to explore a parameterized statistical model to understand different possible futures.`}</p>
    <p>{`Marshall Project `}<a parentName="p" {...{
        "href": "https://www.themarshallproject.org/2015/08/04/the-new-science-of-sentencing#.kkwYOwahP"
      }}>{`The New Science of Sentencing`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://www.infinitecuriosity.org/vizgp/"
      }}>{`Interactive Gaussian Process Visualization`}</a></p>
    <p>{`Google `}<a parentName="p" {...{
        "href": "https://pair.withgoogle.com/explorables/fill-in-the-blank/"
      }}>{`What Have Language Models Learned?`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/Jay4w/status/1256277093387833345"
      }}>{`CNN Explainer: Learning Convolutional Neural Networks with Interactive Visualization`}</a></p>
    <p>{`Google PAIR `}<a parentName="p" {...{
        "href": "https://twitter.com/adamrpearce/status/1228389562193412096"
      }}>{`model uncertainty over space`}</a>{` && `}<a parentName="p" {...{
        "href": "https://twitter.com/adamrpearce/status/1228389774618132481"
      }}>{`demo`}</a>{` && `}<a parentName="p" {...{
        "href": "https://pair-code.github.io/interpretability/uncertainty-over-space/"
      }}>{`direct link`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://distill.pub/2019/activation-atlas/"
      }}>{`Exploring Neural Networks with Activation Atlases`}</a></p>
    <p>{`Google Brain `}<a parentName="p" {...{
        "href": "https://distill.pub/2018/building-blocks/"
      }}>{`The Building Blocks of Interpretability`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://jgoertler.com/visual-exploration-gaussian-processes/"
      }}>{`A Visual Exploration of Gaussian Processes`}</a></p>
    <p>{`Google `}<a parentName="p" {...{
        "href": "http://research.google.com/bigpicture/attacking-discrimination-in-ml/"
      }}>{`Attacking discrimination with smarter machine learning`}</a></p>
    <p>{`Cornell Lab of Ornithology `}<a parentName="p" {...{
        "href": "https://experiments.withgoogle.com/ai/bird-sounds/view/"
      }}>{`bird sounds`}</a></p>
    <p>{`UW `}<a parentName="p" {...{
        "href": "http://idl.cs.washington.edu/papers/tfgraph"
      }}>{`Visualizing Dataflow Graphs of Deep Learning Models in TensorFlow`}</a></p>
    <p>{`NVidia `}<a parentName="p" {...{
        "href": "https://80.lv/articles/nvidia-presented-stylegan2/"
      }}>{`StyleGAN2`}</a></p>
    <p>{`MetaAI `}<a parentName="p" {...{
        "href": "https://ai.facebook.com/blog/hiplot-high-dimensional-interactive-plots-made-easy/"
      }}>{`HiPlot: High-dimensional interactive plots made easy`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://setosa.io/ev/principal-component-analysis/"
      }}>{`pca explained visually`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://research.google.com/bigpicture/"
      }}>{`big picture`}</a></p>
    <p>{`U Arizona `}<a parentName="p" {...{
        "href": "https://tiga1231.github.io/umap-tour/"
      }}>{`Comparing DNNs with UMAP`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.complexity-explorables.org/explorables/hopfed-turingles/"
      }}>{`Pattern Formation in a simple reaction-diffusion system`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://observablehq.com/@mbostock/predator-and-prey"
      }}>{`Predator and prey`}</a></p>
    <p>{`Mozilla `}<a parentName="p" {...{
        "href": "https://www.orion-search.org/"
      }}>{`Orion`}</a>{` && `}<a parentName="p" {...{
        "href": "https://foundation.mozilla.org/en/blog/open-source-tool-accelerate-scientific-knowledge-discovery/"
      }}>{`Mozilla blog post about Orion`}</a></p>
    <p>{`rPsychologist `}<a parentName="p" {...{
        "href": "https://rpsychologist.com/d3/ci/"
      }}>{`Interpreting Confidence Intervals an interactive visualization`}</a></p>
    <p>{`rPsychologist `}<a parentName="p" {...{
        "href": "https://twitter.com/krstoffr/status/1227601599238942722"
      }}>{`Understanding Maximum Likelihood`}</a></p>
    <p>{`538 `}<a parentName="p" {...{
        "href": "https://projects.fivethirtyeight.com/trump-biden-election-map/"
      }}>{`2020 election outcomes`}</a></p>
    <p>{`NYT `}<a parentName="p" {...{
        "href": "https://twitter.com/mathisonian/status/1363204069410574336"
      }}>{`When Could the United States Reach Herd Immunity?`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://greydanus.github.io/2017/01/07/enigma-rnn/"
      }}>{`Learning the Enigma with Recurrent Neural Networks`}</a>{` && `}<a parentName="p" {...{
        "href": "https://twitter.com/hardmaru/status/888565308029419520"
      }}>{`hardmaru tweet`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://galaxy.opensyllabus.org/"
      }}>{`Open Syllabus Galaxy`}</a></p>
    <p>{`Google `}<a parentName="p" {...{
        "href": "https://pair.withgoogle.com/explorables/"
      }}>{`PAIR explorables`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/jesse_vig/status/1384162683897794584"
      }}>{`SummVis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://medium.com/@bethaniemaples/100-years-of-science-fiction-themes-or-a-time-lapse-web-of-human-dreams-3a5d75f5d269"
      }}>{`100 years of sci fi`}</a></p>
    <p>{`Google `}<a parentName="p" {...{
        "href": "https://artsexperiments.withgoogle.com/ocean-of-books?latitude=-43.2000&longitude=-2.5000&zoom=4.40"
      }}>{`Ocean of Books UMAP`}</a></p>
    <p>{`GoogleAI `}<a parentName="p" {...{
        "href": "https://twitter.com/iftenney/status/1295821601972219904"
      }}>{`Language Interoperability Tool`}</a></p>
    <p>{`Nature `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=GW4s58u8PZo"
      }}>{`150 years of nature papers video tour`}</a>{` && `}<a parentName="p" {...{
        "href": "https://www.nature.com/immersive/d41586-019-03165-4/index.html"
      }}>{`explorable`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://arogozhnikov.github.io/2016/12/19/markov_chain_monte_carlo.html"
      }}>{`Hamiltonian Monte Carlo explained`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/_ScottCondron/status/1284495384241479681"
      }}>{`genetic algorithm dashboard`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://europepmc.org/article/PMC/186604"
      }}>{`UCSC Genome Browser`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://projects.interacta.io/country-tsne/"
      }}>{`country data interactive tsne`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/ComplexExplorer/status/1238522600965447681"
      }}>{`agent based model, covid`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/anvaka/status/1262052384294895616"
      }}>{`elmlang`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/gabeeegoooh/status/1239574338740695040"
      }}>{`Epidemic Calculator`}</a></p>
    <p>{`MIT `}<a parentName="p" {...{
        "href": "https://inequality.media.mit.edu/"
      }}>{`place inequality`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/phytools_liam/status/1039654328573812736"
      }}>{`tools for teaching population genetics`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://observablehq.com/@bronna/parametric-seashell"
      }}>{`Parametric Seashells`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/C4COMPUTATION/status/961619897464774656"
      }}>{`complexity explorables`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/lanpa/tensorboardX"
      }}>{`tensorboardX`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/flowingdata/status/966724921765150721"
      }}>{`waiting for a table to open up`}</a></p>
    <p>{`MIT `}<a parentName="p" {...{
        "href": "https://twitter.com/cesifoti/status/930186046909935626"
      }}>{`collective debate`}</a></p>
    <p>{`NYU `}<a parentName="p" {...{
        "href": "https://cognitivemedium.com/interfaces-1/index.html"
      }}>{`chalktalk`}</a>{` && `}<a parentName="p" {...{
        "href": "https://twitter.com/HCI_Research/status/1251898938967207937"
      }}>{`AR demo`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://livefreeordichotomize.com/2017/10/14/mcmc-and-the-case-of-the-spilled-seeds/"
      }}>{`MCMC and the case of the spilled seeds`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://mathisonian.github.io/lorenz/"
      }}>{`lorenz attractor`}</a></p>
    <p>{`setosa `}<a parentName="p" {...{
        "href": "https://setosa.io/ev/eigenvectors-and-eigenvalues/"
      }}>{`Eigenvectors and eigenvalues explained visually`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://fontjoy.com/projector/"
      }}>{`embedding projector`}</a></p>
    <p>{`better explained `}<a parentName="p" {...{
        "href": "https://betterexplained.com/articles/an-interactive-guide-to-the-fourier-transform/"
      }}>{`An Interactive Guide To The Fourier Transform`}</a>{` && `}<a parentName="p" {...{
        "href": "https://betterexplained.com/"
      }}>{`many others`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/merenbey/status/856521515516518400"
      }}>{`aligned hierarchy two ways`}</a></p>
    <p>{`Google PAIR `}<a parentName="p" {...{
        "href": "https://ai.googleblog.com/2017/07/facets-open-source-visualization-tool.html"
      }}>{`facets`}</a>{` && `}<a parentName="p" {...{
        "href": "https://pair-code.github.io/facets/"
      }}>{`blog post`}</a>{` && `}<a parentName="p" {...{
        "href": "https://github.com/PAIR-code/facets"
      }}>{`github`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://philogb.github.io/page/wind/"
      }}>{`wind simulation over terrain`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://teachablemachine.withgoogle.com/"
      }}>{`teachable machine`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.decisionproblem.com/paperclips/"
      }}>{`universal paperclips`}</a>{` && `}<a parentName="p" {...{
        "href": "https://blog.ycombinator.com/frank-lantz-director-of-nyus-game-center-and-creator-of-universal-paperclips/"
      }}>{`Frank Lantz interview`}</a>{` && `}<a parentName="p" {...{
        "href": "http://www.franklantz.net/work/#/universal-paperclips/"
      }}>{`project page`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.nytimes.com/interactive/2014/upshot/buy-rent-calculator.html"
      }}>{`buy or rent?`}</a></p>
    <p>{`sciam `}<a parentName="p" {...{
        "href": "https://www.scientificamerican.com/article/flavor-connection-taste-map-interactive/"
      }}>{`flavor compounds`}</a></p>
    <p>{`538 `}<a parentName="p" {...{
        "href": "https://projects.fivethirtyeight.com/complete-history-of-the-nfl/#sea"
      }}>{`history of nfl`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://incredible.pm/"
      }}>{`the incredible proof machine`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://setosa.io/conditional/"
      }}>{`conditional probability`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://explainshell.com/explain?cmd=cd+.."
      }}>{`explain shell`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://flowingdata.com/2016/01/19/how-you-will-die/"
      }}>{`how you will die`}</a>{` && `}<a parentName="p" {...{
        "href": "https://flowingdata.com/2015/09/23/years-you-have-left-to-live-probably/"
      }}>{`years you have left to live, probably`}</a></p>
    <p>{`538 `}<a parentName="p" {...{
        "href": "https://fivethirtyeight.com/features/science-isnt-broken/#part1"
      }}>{`science isn't broken`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://setosa.io/ev/pi/"
      }}>{`pi`}</a>{` && `}<a parentName="p" {...{
        "href": "https://setosa.io/ev/exponentiation/"
      }}>{`exponentiation`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://playground.tensorflow.org/#activation=tanh&batchSize=10&dataset=circle&regDataset=reg-plane&learningRate=0.03&regularizationRate=0&noise=0&networkShape=4,2&seed=0.26939&showTestData=false&discretize=false&percTrainData=50&x=true&y=true&xTimesY=true&xSquared=false&ySquared=false&cosX=false&sinX=false&cosY=false&sinY=false&collectStats=false&problem=classification&initZero=false&hideText=false"
      }}>{`tensorflow playground`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://jackschaedler.github.io/circles-sines-signals/complex.html"
      }}>{`primer on signal processing`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/with_modality/status/1486000110068809733"
      }}>{`with modality`}</a></p>
    <h2>{`Other data exploration interfaces`}</h2>
    <p><a parentName="p" {...{
        "href": "https://archive.nytimes.com/www.nytimes.com/interactive/2012/02/13/us/politics/2013-budget-proposal-graphic.html"
      }}>{`Four Ways to Slice Obama’s 2013 Budget Proposal`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.gapminder.org/tools/#$chart-type=bubbles&url=v1"
      }}>{`gapminder`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://orbis.stanford.edu/"
      }}>{`ORBIS: The Stanford Geospatial Network Model of the Roman World`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.nytimes.com/interactive/2014/02/09/opinion/minimum-wage.html"
      }}>{`Can you live on the minimum wage`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://htwins.net/scale2/"
      }}>{`scale of the universe`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://globe.cid.harvard.edu/?mode=productspace3D&id=JM"
      }}>{`globe of economic complexity`}</a></p>
    <p>{`guardian `}<a parentName="p" {...{
        "href": "https://www.theguardian.com/sport/interactive/2013/jan/30/nfl-salaries-team-position#houston-texans,denver-broncos"
      }}>{`NFL salaries by team and position`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/hamiltonulmer/status/1516858639729913856?s=20&t=Y2OL_GFL1_09OjtDgyYkAg"
      }}>{`rill data`}</a></p>
    <p>{`nyt `}<a parentName="p" {...{
        "href": "https://archive.nytimes.com/www.nytimes.com/packages/html/national/20050515_CLASS_GRAPHIC/index_02.html"
      }}>{`class matters`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://stars.chromeexperiments.com/"
      }}>{`stars`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://histography.io/"
      }}>{`wikipedia as a timeline`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://uclab.fh-potsdam.de/coins/"
      }}>{`coins`}</a></p>
    <p>{`cfpb `}<a parentName="p" {...{
        "href": "https://www.consumerfinance.gov/owning-a-home/explore-rates/"
      }}>{`explore interest rates`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://tbaldw.in/nyc-buildings/"
      }}>{`all the buildings in Manhattan`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://observablehq.com/@tmcw/enigma-machine"
      }}>{`enigma machine`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.chessroots.com/"
      }}>{`chess roots`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://fathom.info/miles-web/"
      }}>{`Miles Davis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://pudding.cool/2017/02/vocabulary/index.html"
      }}>{`hiphop vocabulary`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.worldtimebuddy.com/"
      }}>{`world time buddy`}</a>{` && `}<a parentName="p" {...{
        "href": "https://everytimezone.com/"
      }}>{`every time zone`}</a></p>
    <p>{`esa `}<a parentName="p" {...{
        "href": "https://sci.esa.int/star_mapper/"
      }}>{`star mapper`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://public.tableau.com/app/profile/mckinsey.analytics/viz/AutomationandUSjobs/Technicalpotentialforautomation"
      }}>{`Automation potential and wages for US Jobs`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://comtrade.un.org/labs/"
      }}>{`exploring trade data`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://observablehq.com/@observablehq/electoral-college-decision-tree"
      }}>{`Electoral College Decision Tree`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://flowingdata.com/2014/07/02/jobs-charted-by-state-and-salary/"
      }}>{`jobs by state and salary`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.reddit.com/r/d3js/comments/xh2ks/nutrition_parallel_coordinates_canvas/"
      }}>{`nutrient parallel coordinates`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://nycommons.org/"
      }}>{`NYC commons`}</a></p>
    <p>{`bbc `}<a parentName="p" {...{
        "href": "https://www.bbc.co.uk/news/business-15748696"
      }}>{`eurozone debt web`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://blog.csaladen.es/refugees/"
      }}>{`refugee flows`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://polygraph.cool/kickstarter/"
      }}>{`history of kickstarter by city`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://pudding.cool/2017/03/timeless/index.html"
      }}>{`popularity of older music on Spotify`}</a></p>
    <p>{`NYT `}<a parentName="p" {...{
        "href": "http://archive.nytimes.com/www.nytimes.com/interactive/2012/11/02/us/politics/paths-to-the-white-house.html"
      }}>{`512 Paths to the White House`}</a></p>
    <p>{`NASA `}<a parentName="p" {...{
        "href": "https://exoplanetarchive.ipac.caltech.edu/cgi-bin/IcePlotter/nph-icePlotInit?mode=demo&set=confirmed"
      }}>{`Exoplanet Archive`}</a></p>
    <p>{`Cornell `}<a parentName="p" {...{
        "href": "https://www.birds.cornell.edu/home/"
      }}>{`Birds`}</a></p>
    <p>{`Quanta `}<a parentName="p" {...{
        "href": "https://www.quantamagazine.org/a-new-map-of-the-standard-model-of-particle-physics-20201022/#example"
      }}>{`A New Map of All the Particles and Forces`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://historiacartarum.org/eel-rents-project/english-eel-rents-10th-17th-centuries/"
      }}>{`English Eel-Rents: 10th-17th Centuries`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://observablehq.com/@tophtucker/multiplication-and-division-by-similar-triangles"
      }}>{`Multiplication and Division by Similar Triangles`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://uniswapv3.flipsidecrypto.com/"
      }}>{`Uniswap fee calculator`}</a></p>
    <p>{`Our World in Data `}<a parentName="p" {...{
        "href": "https://twitter.com/erniedesigns/status/1334925906402480133"
      }}>{`COVID-19 Data Explorer`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.nytimes.com/interactive/2014/06/05/upshot/how-the-recession-reshaped-the-economy-in-255-charts.html"
      }}>{`2007 recession in 255 charts`}</a></p>
    <p>{`U Chicago `}<a parentName="p" {...{
        "href": "https://us-sankey.rcc.uchicago.edu/"
      }}>{`US Energy History Sankey`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://macrostrat.org/"
      }}>{`Macrostrat`}</a></p>
    <p>{`Carbon Plan `}<a parentName="p" {...{
        "href": "https://carbonplan.org/research/dac-calculator"
      }}>{`carbon capture cost calculator`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.indexventures.com/optionplan/#initial_amount_raised=1500000&expected_funding_rounds_pre_exit=series-a&employee_country=us&mode=seed"
      }}>{`stock options benchmark calculator`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://observablehq.com/@danmarshall/sanddance-explorer?collection=@danmarshall/sanddance"
      }}>{`SandDance`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://anvaka.github.io/map-of-reddit/?x=472339.7985989978&y=-67566.15777784647&z=1060220.437000989"
      }}>{`Map of Reddit`}</a>{` && `}<a parentName="p" {...{
        "href": "https://twitter.com/anvaka/status/1378429734296559617"
      }}>{`webgl svg renderer`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.vesselfinder.com/"
      }}>{`Vessel Finder`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://public.tableau.com/app/profile/scott.cole/viz/BurritosinSanDiego/MainDash"
      }}>{`Burritos in San Dieg`}</a></p>
    <p><a parentName="p" {...{
        "href": "alltrails.com"
      }}>{`All Trails (pro version)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/theosanderson/status/1412078714557435904"
      }}>{`cov2tree`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/galaxy_mapGames/status/1408922344014462978"
      }}>{`galaxy_mapGames`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.ventusky.com/?p=44.7;-121.9;4&l=gust&w=0xIAb9A9A"
      }}>{`ventusky wind map`}</a></p>
    <p>{`NASA `}<a parentName="p" {...{
        "href": "https://sealevel.nasa.gov/"
      }}>{`Sea level change maps`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.simplybusiness.co.uk/microsites/hungry-tech/"
      }}>{`big tech acquisitions 1999 to 2014`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/Wattenberger/status/1423259472919674882"
      }}>{`Visualizing Github codebases`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://mathlets.org/mathlets/creating-the-derivative/"
      }}>{`Mathlets`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/hockendougal/status/1433809971691524134"
      }}>{`Manifest`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.shipmap.org/"
      }}>{`shipmap`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://globe.gl/example/submarine-cables/"
      }}>{`Submarine cables on a webgl globe`}</a></p>
    <p>{`ProPublica `}<a parentName="p" {...{
        "href": "https://projects.propublica.org/toxmap/"
      }}>{`The Most Detailed Map of Cancer-Causing Industrial Air Pollution in the U.S.`}</a></p>
    <p>{`nyt `}<a parentName="p" {...{
        "href": "https://www.nytimes.com/interactive/2015/05/17/us/elections/2016-presidential-campaigns-staff-connections-clinton-bush-cruz-paul-rubio-walker.html"
      }}>{`campaign staff connections`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.digitalattackmap.com/#anim=1&color=0&country=ALL&list=1&time=16000&view=map"
      }}>{`digital attack map`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://projects.fivethirtyeight.com/reddit-ngram/?keyword=lol.win.omg.cute.fail.wtf&start=20071015&end=20170731&smoothing=10"
      }}>{`reddit ngram viewer`}</a></p>
    <p>{`Audubon `}<a parentName="p" {...{
        "href": "https://www.audubon.org/climate/survivalbydegrees"
      }}>{`Survival by Degrees`}</a></p>
    <p>{`Stamen `}<a parentName="p" {...{
        "href": "https://stamen.com/work/crimespotting/"
      }}>{`Crimespotting`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://oec.world/en/resources/about/"
      }}>{`The Observatory of Economic Complexity`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://saraquigley.github.io/uc-trends/"
      }}>{`University of California System`}</a></p>
    <p>{`NYT `}<a parentName="p" {...{
        "href": "https://archive.nytimes.com/www.nytimes.com/pages/national/class/index.html"
      }}>{`Class Matters`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://proteinpercent.com/"
      }}>{`Protein Percent`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://maps.usgs.gov/padus/"
      }}>{`Protected Areas Database of the United States`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://tools.airfire.org/websky/v2/run/standard/PNW4km-CMAQ/2020090800/#viewer"
      }}>{`US wildfire and smoke`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/pbesh/status/1208451307687817217"
      }}>{`faceted search with react three fiber`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/mithi/hexapod-robot-simulator"
      }}>{`hexapod robot simulator`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://aatishb.com/covidtrends/"
      }}>{`covid new cases vs total cases`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://alhill.shinyapps.io/COVID19seir/"
      }}>{`Covid vs healthcare capacity`}</a></p>
    <p>{`Cruise `}<a parentName="p" {...{
        "href": "https://webviz.io/app/?demo="
      }}>{`webviz`}</a></p>
    <p>{`Economist `}<a parentName="p" {...{
        "href": "https://twitter.com/martgnz/status/1219998475355664384"
      }}>{`Democracy Index`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://ss2.climatecentral.org/#11/40.0281/-74.0327?show=satellite&projections=0-K14_RCP85-SLR&level=10&unit=meters&pois=hide"
      }}>{`surging seas risk zones`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/awoodruff/status/1190992385360252929"
      }}>{`dst`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://pudding.cool/2017/01/shape-of-slavery/"
      }}>{`Shape of slavery`}</a></p>
    <p>{`Uber `}<a parentName="p" {...{
        "href": "https://eng.uber.com/maze/"
      }}>{`maze`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://textbooks.math.gatech.edu/ila/complex-eigenvalues.html#cplx-diagonalization-thm"
      }}>{`interactive linear algebra`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.tasteatlas.com/europe/soups/map"
      }}>{`soup taste atlas`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://dataexplorer.womenwill.com/intl/en/dashboard/#/g5_5_slice/a2:1%7Ccountry:10"
      }}>{`women in the workforce global survey`}</a></p>
    <p>{`Google `}<a parentName="p" {...{
        "href": "https://research.google.com/bigpicture/elements/"
      }}>{`interactive periodic table`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/_joelsimon/status/1120495722741288966?s=20"
      }}>{`generative architecture`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://codex-atlanticus.it/#/Overview"
      }}>{`Davinci's writings`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.blocklayer.com/"
      }}>{`block layer calculators`}</a></p>
    <p>{`USGS `}<a parentName="p" {...{
        "href": "https://eerscmap.usgs.gov/uswtdb/viewer/#3/37.25/-96.25"
      }}>{`wind turbines`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://cartographers-nk.wonyoung.so/"
      }}>{`OpenStreetMap Cartographers of North Korea`}</a></p>
    <p>{`NYT `}{`[mapping segregation]`}{`(`}<a parentName="p" {...{
        "href": "https://www.nytimes.com/interactive/2015/07/08/us/census-race-map.html"
      }}>{`https://www.nytimes.com/interactive/2015/07/08/us/census-race-map.html`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://genopro.com/genogram/emotional-relationships/"
      }}>{`emotional relationships in genograms`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.ocearch.org/tracker/detail/bruin"
      }}>{`shark tracker`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.meteorshowers.org/"
      }}>{`Ursids meteor shower`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://dinosaurpictures.org/ancient-earth/"
      }}>{`ancient earth`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/nikolaj_dehaan/status/1541404981709877248?s=20&t=TKRgCu1CWVD3wUzZ9o6Wkw"
      }}>{`sound, synths`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://truth-and-beauty.net/projects/peakspotting"
      }}>{`peak spotting`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://polyhedra.tessera.li/"
      }}>{`Polyhedra Viewer`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://ngz.gispocoding.fi/kepler/?locale=en"
      }}>{`cargo on Finnish roads`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://mbtaviz.github.io/"
      }}>{`Visualizing MBTA Data`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://exoplanetexplore.vercel.app/"
      }}>{`An Analysis report on Exoplanets`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://qiao.github.io/PathFinding.js/visual/"
      }}>{`pathfinding algos`}</a></p>
    <p>{`Economist `}<a parentName="p" {...{
        "href": "https://www.economist.com/big-mac-index?fsrc=scn%2Ftw%2Fte%2Fbl%2Fed%2Fthebigmacindexburgernomics"
      }}>{`Big Mac index`}</a>{` && `}<a parentName="p" {...{
        "href": "https://source.opennews.org/articles/how-we-made-new-big-mac-index-interactive/"
      }}>{`How we made the new Big Mac index interactive`}</a></p>
    <p>{`Bloomberg `}<a parentName="p" {...{
        "href": "https://www.bloomberg.com/graphics/2015-whats-warming-the-world/"
      }}>{`What's really warming the world`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.jasondavies.com/primos/"
      }}>{`prime number patterns`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://xeno.graphics/"
      }}>{`Xenographics: Weird but (sometimes) useful charts`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://ofrohn.github.io/celestial-demo/viewer.html"
      }}>{`d3 celestial`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://pudding.cool/2017/01/making-it-big/"
      }}>{`What three years and 75,000 shows in New York tell us about the chance your favorite band will succeed`}</a></p>
    <p>{`upshot `}<a parentName="p" {...{
        "href": "https://www.nytimes.com/interactive/2018/03/27/upshot/make-your-own-mobility-animation.html"
      }}>{`income mobility`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/simongerman600/status/938895480041254912"
      }}>{`precipitation over time`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://nuclearsecrecy.com/nukemap/"
      }}>{`nukemap`}</a></p>
    <p>{`anvaka `}<a parentName="p" {...{
        "href": "https://anvaka.github.io/fieldplay/?cx=0&cy=0&w=8.5398&h=8.5398&dt=0.01&fo=0.998&dp=0.009&cm=1"
      }}>{`field play`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/adamemccann/status/849221662209961984"
      }}>{`nfl combine results`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://plaintalkhistory.com/monroeandflorencework/explore/map2/#3.5/38/-97.5"
      }}>{`map of lynching in US`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://genome.ucsc.edu/cgi-bin/hgGateway"
      }}>{`genome browser gateway`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.allencell.org/"
      }}>{`Allen cell explorer`}</a></p>
    <p>{`Shirley Wu `}<a parentName="p" {...{
        "href": "http://explore-adventure.com/"
      }}>{`exlporing travel searches`}</a></p>
    <p>{`538 `}<a parentName="p" {...{
        "href": "https://fivethirtyeight.com/features/gun-deaths/"
      }}>{`gun deaths`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/rachaelgleason/status/901466061224247298"
      }}>{`removal of Houston wetlands`}</a></p>
    <p>{`nyt `}<a parentName="p" {...{
        "href": "https://archive.nytimes.com/www.nytimes.com/interactive/2013/10/02/us/uninsured-americans-map.html?_r=0"
      }}>{`Where Poor and Uninsured Americans Live`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.nytimes.com/interactive/2014/08/13/upshot/where-people-in-each-state-were-born.html"
      }}>{`how Americans have moved between states since 1900`}</a></p>
    <p>{`nyt `}<a parentName="p" {...{
        "href": "https://www.nytimes.com/interactive/2014/11/04/upshot/senate-maps.html"
      }}>{`2014 midterm election maps`}</a></p>
    <p>{`538 `}<a parentName="p" {...{
        "href": "https://projects.fivethirtyeight.com/election-2016/delegate-targets/democrats/"
      }}>{`Who’s On Track For The Nomination?`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://lifemap.univ-lyon1.fr/explore.html"
      }}>{`life map`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://visualizationuniverse.com/"
      }}>{`search trends in visualization`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://higlass.io/app/?config=Q5LdNchQRLSZ_0yKsTEoiw"
      }}>{`higlass`}</a></p>
    <p><a parentName="p" {...{
        "href": ""
      }}></a></p>
    <p><a parentName="p" {...{
        "href": ""
      }}></a></p>
    <p><a parentName="p" {...{
        "href": ""
      }}></a></p>
    <h2>{`Talks, Posts & Theory`}</h2>
    <p>{`Narratives, blog posts, collections of papers, books`}</p>
    <p><a parentName="p" {...{
        "href": "http://worrydream.com/"
      }}>{`worrydream chapters 3 & 4`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://mucollective.northwestern.edu/"
      }}>{`Midwest Uncertainty Collective papers`}</a></p>
    <p>{`ncase `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=PCwtsK_FhUw"
      }}>{`seeing whole systems`}</a></p>
    <p>{`Bartosz Ciechanowski `}<a parentName="p" {...{
        "href": "https://ciechanow.ski/color-spaces/"
      }}>{`color spaces`}</a>{` && `}<a parentName="p" {...{
        "href": "https://ciechanow.ski/archives/"
      }}>{`everything else as well`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://worrydream.com/MediaForThinkingTheUnthinkable/"
      }}>{`Media For Thinking The Unthinkable`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://visav.vizhub.ai/"
      }}>{`IEEE papers, clustered`}</a></p>
    <p>{`UW IDL `}<a parentName="p" {...{
        "href": "https://idl.cs.washington.edu/files/2018-Meridian-CHI.pdf"
      }}>{`Towards Design Principles for Visual Analytics in Operations Contexts`}</a>{` && `}<a parentName="p" {...{
        "href": "https://idl.cs.washington.edu/papers/meridian/"
      }}>{`IDL site`}</a></p>
    <p>{`VISxAI `}<a parentName="p" {...{
        "href": "http://visxai.io/"
      }}>{`Entries for annual workshops on Visualization for AI Explainability`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://features.propublica.org/navy-uss-mccain-crash/navy-installed-touch-screen-steering-ten-sailors-paid-with-their-lives/"
      }}>{`The Navy installed touch-screen steering systems to save money. Ten sailors paid with their lives.`}</a>{` and `}<a parentName="p" {...{
        "href": "https://twitter.com/SwiftOnSecurity/status/1208637459896115207"
      }}>{`discussion`}</a></p>
    <p>{`UW `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=N00g9Q9stBo&t=2s"
      }}>{`History of data visualization`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/mathisonian/awesome-visualization-research/blob/master/README.md"
      }}>{`awesome visualization research`}</a></p>
    <p>{`Uber `}<a parentName="p" {...{
        "href": "https://eng.uber.com/data-visualization-intelligence/"
      }}>{`data viz team`}</a></p>
    <p>{`UW `}<a parentName="p" {...{
        "href": "https://courses.cs.washington.edu/courses/cse512/21sp/"
      }}>{`CSE 512 Spring 2021`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.informationisbeautifulawards.com/showcase/611-the-graphic-continuum"
      }}>{`The Graphic Continuum`}</a></p>
    <p>{`ASA `}<a parentName="p" {...{
        "href": "https://community.amstat.org/jointscsg-section/media/videos"
      }}>{`Statistical Graphics Video Library`}</a></p>
    <p>{`Chris Olah `}<a parentName="p" {...{
        "href": "https://colah.github.io/"
      }}>{`blog entries`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://medium.com/@namwookkim/visualization-papers-at-chi-2018-94df5bf40aca"
      }}>{`Visualization papers at CHI 2018`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seaborn.pydata.org/tutorial/distributions.html"
      }}>{`seaborn on Visualizing distributions of data`}</a>{` && `}<a parentName="p" {...{
        "href": "https://www.darkhorseanalytics.com/blog/visualizing-distributions-3"
      }}>{`dark horse analytics on the same`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.redblobgames.com/grids/hexagons/"
      }}>{`hexagonal grids`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://vallandingham.me/regl_intro.html"
      }}>{`An Intro to regl for Data Visualization`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://peterbeshai.com/blog/2017-05-26-beautifully-animate-points-with-webgl-and-regl/"
      }}>{`Beautifully Animate Points with WebGL and regl`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://0fps.net/"
      }}>{`mikolalysenko`}</a>{` && `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=ZC6N6An5FVY"
      }}>{`Introducing REGL`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://writings.stephenwolfram.com/2017/11/what-is-a-computational-essay/"
      }}>{`What Is a Computational Essay?`}</a></p>
    <p>{`Kanit "Ham" Wongsuphasawat `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=62k_JMOgFcc"
      }}>{`Augmenting Visualization Tools with Automated Design & Recommendation`}</a></p>
    <p>{`Alberto Cairo `}<a parentName="p" {...{
        "href": "https://www.dropbox.com/sh/jk4ginxyai6ylqu/AABvqdyT1hJtyFN9nKNHyX9Ba?dl=0"
      }}>{`papers on uncertainty`}</a></p>
    <p>{`Heer et al `}<a parentName="p" {...{
        "href": "https://distill.pub/2020/communicating-with-interactive-articles/"
      }}>{`Communicating with Interactive Articles`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://pbs.twimg.com/media/DBiQB8zU0AEAVGC.jpg:large"
      }}>{`ft visual vocabulary`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://experception.net/Franconeri_ExperCeptionDotNet_DataVisQuickRef.pdf"
      }}>{`which viz`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/dataandme/status/1034874845371228162"
      }}>{`cartography cheatsheet`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.python-graph-gallery.com/"
      }}>{`python graph gallery`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://www.r-graph-gallery.com/"
      }}>{`r graph gallery`}</a></p>
    <p>{`Claus O. Wilke `}<a parentName="p" {...{
        "href": "https://clauswilke.com/dataviz/ecdf-qq.html#empirical-cumulative-distribution-functions"
      }}>{`Fundamentals of Data Visualization`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://stats.stackexchange.com/questions/263539/clustering-on-the-output-of-t-sne/264647#264647"
      }}>{`clustering on the output of tsne`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://vanschneider.com/blog/behind-the-scenes-of-the-westworld-ui/"
      }}>{`Behind the scenes of the Westworld UI`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://towardsdatascience.com/better-heatmaps-and-correlation-matrix-plots-in-python-41445d0f2bec"
      }}>{`Better heatmaps`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://observablehq.com/@tophtucker/theres-plenty-of-room-in-the-corners"
      }}>{`plenty of room in the corners`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://dataphys.org/list/bertins-reorderable-matrices/"
      }}>{`physical heatmaps`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=eZSe4xVXHhI"
      }}>{`bad typography`}</a></p>
    <p>{`JPL `}<a parentName="p" {...{
        "href": "https://en.m.wikipedia.org/wiki/Porkchop_plot"
      }}>{`porkchop plots`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=b6YTQJVzwlI"
      }}>{`Pointing to the future of UI`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://medium.com/nightingale/what-board-games-teach-us-about-data-visualization-ded14080b4f4"
      }}>{`What board games teach us about visualization`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://neuralnetworksanddeeplearning.com/chap4.html"
      }}>{`A visual proof that neural nets can compute any function`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://steveharoz.com/research/isotype/"
      }}>{`ISOTYPE Visualization Working Memory, Performance, and Engagement`}</a></p>
    <p>{`538 `}<a parentName="p" {...{
        "href": "https://fivethirtyeight.com/features/how-mapping-shots-in-the-nba-changed-it-forever/"
      }}>{`How Mapping Shots In The NBA Changed It Forever`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.lcbc.nl/publications/2019_eurovis/"
      }}>{`Focus+Context Exploration of Hierarchical Embeddings`}</a></p>
    <p>{`NYT `}<a parentName="p" {...{
        "href": "https://www.nytimes.com/interactive/2019/08/29/opinion/hurricane-dorian-forecast-map.html"
      }}>{`Those hurricane maps don't mean what you think they mean`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://towardsdatascience.com/how-exactly-umap-works-13e3040e1668"
      }}>{`How exactly umap works`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://medium.com/@chrisgaul/tokyo-subways-humble-duct-tape-typographer-a8c84bb6b99b"
      }}>{`Tokyo duct tape typography for temporary construction signage`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://earthobservatory.nasa.gov/images/92654/just-another-day-on-aerosol-earth"
      }}>{`Aerosol Earth`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://www.csb.yale.edu/userguides/graphics/explorer/html/render/issue10/issue10_5.html"
      }}>{`IRIS explorer`}</a></p>
    <p>{`Economist `}<a parentName="p" {...{
        "href": "https://twitter.com/graham_douglas/status/1034789533886427136"
      }}>{`history of visualization`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Timeline_of_first_images_of_Earth_from_space#cite_note-24"
      }}>{`timeline of first images of Earth from space`}</a>{` && WaPo `}<a parentName="p" {...{
        "href": "https://www.washingtonpost.com/blogs/achenblog/post/spaceship-earth-the-first-photos/2012/01/03/gIQAzXF5XP_blog.html"
      }}>{`Spaceship Earth: The first photos`}</a>{` && `}<a parentName="p" {...{
        "href": "https://twitter.com/longnow/status/999125030607843328"
      }}>{`longnow`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/JazmynLatimer/status/1039298960614027264"
      }}>{`better legislative digests`}</a>{` and `}<a parentName="p" {...{
        "href": "https://tldrlegal.com/license/youtube-terms-of-service"
      }}>{`tldr legal`}</a></p>
    <p>{`waitbutwhy `}<a parentName="p" {...{
        "href": "https://waitbutwhy.com/2013/12/how-to-name-baby.html"
      }}>{`how to name a baby`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://upset.app/"
      }}>{`UpSet plot`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://www.hiveplot.com/"
      }}>{`hive plot`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.statisticsdonewrong.com/"
      }}>{`statistics done wrong`}</a></p>
    <p>{`@hypotext `}<a parentName="p" {...{
        "href": "https://www.cs.cmu.edu/~kqy/resources/Penrose_PLunch_slides.pdf"
      }}>{`A proposal for designing extensible, domain-specific languages for mathematical diagrams`}</a></p>
    <p>{`Economist `}<a parentName="p" {...{
        "href": "https://medium.economist.com/data-journalism-at-the-economist-gets-a-home-of-its-own-in-print-92e194c7f67e"
      }}>{`Launch of graphic detail`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://medium.com/@Marianattestad/a-treatise-on-making-circos-plots-from-genomic-data-7ff496849e0"
      }}>{`circos`}</a></p>
    <p>{`538 `}<a parentName="p" {...{
        "href": "https://fivethirtyeight.com/features/the-45-best-and-weirdest-charts-we-made-in-2018/"
      }}>{`The 45 Best — And Weirdest — Charts We Made In 2018`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://observablehq.com/@nsthorat/introduction-to-deeplearn-js"
      }}>{`Introduction to the TensorFlow.js core API`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://vallandingham.me/multivariate_maps.html"
      }}>{`multivariate maps`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://hi.stamen.com/stamen-exit-interview-zan-armstrong-d44d06e89d6d"
      }}>{`Stamen practitioners series: a conversation with Zan Armstrong`}</a></p>
    <p>{`cruise `}<a parentName="p" {...{
        "href": "https://medium.com/cruise/hd-maps-self-driving-cars-b6444720021c"
      }}>{`lidar maps`}</a></p>
    <p><a parentName="p" {...{
        "href": ""
      }}></a></p>
    <p><a parentName="p" {...{
        "href": ""
      }}></a></p>
    <p><a parentName="p" {...{
        "href": ""
      }}></a></p>
    <h2>{`Data dense interfaces & physical visualizations`}</h2>
    <p>{`Physical visualizations, old visualizations on paper`}</p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/engineers_feed/status/1315523404775280642"
      }}>{`Cosmonauts’ information display system`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/annieminoff/status/1147621714005827584/photo/2"
      }}>{`particle accelerator in the basement of the Louvre`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=rAZavOcEnLg"
      }}>{`Making Money Flow: The MONIAC`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://miegakure.com/"
      }}>{`4d puzzles`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://aviz.fr/bertifier_app/"
      }}>{`bertifier`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.islandix.com/"
      }}>{`bike wheel builder`}</a></p>
    <p>{`Buckminster Fuller `}<a parentName="p" {...{
        "href": "https://books.google.com/books?id=WlEEAAAAMBAJ&pg=PA41&source=gbs_toc_r&hl=en#v=onepage&q&f=false"
      }}>{`cutout dymaxion globe`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://getcoleman.com/"
      }}>{`get coleman`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/simongerman600/status/1053801296011190274"
      }}>{`man on bicycle`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.telegraph.co.uk/travel/news/What-travelling-was-like-100-years-ago/"
      }}>{`isochronic distances`}</a>{` && `}<a parentName="p" {...{
        "href": "https://urbandemographics.blogspot.com/2018/05/visualizing-space-time-networks.html"
      }}>{`UK transportation networks`}</a></p>
    <p>{`Stanford `}<a parentName="p" {...{
        "href": "https://www.davidrumsey.com/luna/servlet/view/search?search=SUBMIT&q=album+statistique&dateRangeStart=&dateRangeEnd=&sort=pub_date,pub_list_no,series_no,series_no&QuickSearchA=QuickSearchA"
      }}>{`David Rumsey map collection`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.thisiscolossal.com/2019/03/lines-hebrides/"
      }}>{`Searing Bands of White Light Mark the Ocean’s Rising Tides in a Coastal Community`}</a></p>
    <p>{`National Geographic `}<a parentName="p" {...{
        "href": "https://twitter.com/NatGeoMaps/status/995454865064251392"
      }}>{`bird migration`}</a></p>
    <p>{`National Geographic `}<a parentName="p" {...{
        "href": "https://twitter.com/NatGeoMaps/status/993899989012279298"
      }}>{`Virgin Islands`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.smithsonianmag.com/arts-culture/why-we-should-teach-music-history-backwards-180955053/?no-ist"
      }}>{`Why We Should Teach Music History Backwards`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=9ksLuRoEq6A"
      }}>{`brain cells play pong`}</a></p>
    <h2>{`Tooling / infra / products / d3 canvas snippets / webgl`}</h2>
    <p>{`These are also data exploration interfaces, but sufficiently general that they accept third party data to some degree (DeepNote is totally general, clustergrammer is not). Some of them are open source libries like Kepler, some of them are products.`}</p>
    <p>{`cruise `}<a parentName="p" {...{
        "href": "https://medium.com/cruise/introducing-worldview-749aaf98112d"
      }}>{`worldview`}</a></p>
    <p>{`cruise `}<a parentName="p" {...{
        "href": "https://medium.com/cruise/webviz-fb5f77ebe52b"
      }}>{`webviz`}</a></p>
    <p>{`airbnb `}<a parentName="p" {...{
        "href": "https://github.com/airbnb/visx"
      }}>{`vx`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/samccone/status/915532878225854464"
      }}>{`worker thread canvas visualizations`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://medialab.github.io/iwanthue/"
      }}>{`iwanthue`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://mermaid-js.github.io/mermaid/#/"
      }}>{`mermaid`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/DataToViz/status/927551703850795013"
      }}>{`d3 annotation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/biolab/orange3"
      }}>{`orange3`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/heshan_cheri/status/1349438469995601922"
      }}>{`unfolded studio`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/zachernuk/status/958945836842475522"
      }}>{`webgl text`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/hypotext/status/1268218080993386497"
      }}>{`penrose`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://lucid.app/"
      }}>{`lucidchart`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://bl.ocks.org/pbeshai/8008075f9ce771ee8be39e8c38907570"
      }}>{`Lasso with d3 v4 and Canvas`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://datoviz.org/"
      }}>{`Datoviz: GPU interactive scientific viz`}</a></p>
    <p>{`UW `}<a parentName="p" {...{
        "href": "https://github.com/vega/falcon"
      }}>{`Falcon: Interactive Visual Analysis for Big Data`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/DeepnoteHQ"
      }}>{`DeepNote`}</a></p>
    <p><a parentName="p" {...{
        "href": "glueviz.org"
      }}>{`Glue viz`}</a>{` && `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=1q38cU6WQB0"
      }}>{`demo`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/franschrandez/status/1225138722930339840"
      }}>{`clustergrammer`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/finos/perspective/blob/master/README.md"
      }}>{`finos perspective`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://kepler.gl/"
      }}>{`uber kepler.gl`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://store.steampowered.com/app/587470/Chroma_Lab/"
      }}>{`Chroma`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://bl.ocks.org/lorenzopub/9ce8ab464e826086a62b785bb296dd17"
      }}>{`d3 force playground`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://nodes.io/"
      }}>{`nodes`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://navio.dev/"
      }}>{`navio`}</a>{` && `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=9lYvVjsstZs"
      }}>{`network explorer explainer video`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/KariLavikka/status/1374765919096995857"
      }}>{`Genome Spy`}</a>{` && `}<a parentName="p" {...{
        "href": "https://genomespy.app/"
      }}>{`genomespy.app`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.unfolded.ai/"
      }}>{`Unfolded geospatial`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.bluebeam.com/"
      }}>{`Bluebeam's architecture tools`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/holoviz/datashader"
      }}>{`datashader`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://jokergoo.github.io/ComplexHeatmap-reference/book/index.html"
      }}>{`Complex Heatmap`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://foxglove.dev/"
      }}>{`FoxGlove robotics debugging`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://mdecks.com/mapharmony.phtml"
      }}>{`Tonal Harmony Pro`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://urbanfootprint.com/"
      }}>{`Urban Footprint`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.omnisci.com/"
      }}>{`omnisci`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://enso.org/#Overview"
      }}>{`enso`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://mode.com/"
      }}>{`mode`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://pitchinteractiveinc.github.io/tilegrams/"
      }}>{`tilegrams`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.causal.app/"
      }}>{`causal app`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://biorender.com/"
      }}>{`biorender`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.graphistry.com/deployment"
      }}>{`graphistry`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://charticulator.com/app/index.html"
      }}>{`charticulator`}</a></p>
    <p>{`Google `}<a parentName="p" {...{
        "href": "https://www.google.com/flights"
      }}>{`flights`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://muzejs.org/demos/wa/latest"
      }}>{`muzejs`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://avs.auto/#/"
      }}>{`autonomous vehicle data`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://rawgraphs.io/"
      }}>{`rawgraphs`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://bl.ocks.org/HarryStevens/69da410602d8ca6b9f41f72024dba791"
      }}>{`choropleth sandbox`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://bl.ocks.org/d3noob/6077996"
      }}>{`dc.js sandbox`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://bl.ocks.org/d3indepth/b6d4845973089bc1012dec1674d3aff8"
      }}>{`d3 curve explorer`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/nicgirault/circosjs"
      }}>{`circos js`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://regexr.com/"
      }}>{`regexr`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      